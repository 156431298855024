.react-flow__node-selectorNode {
    font-size: 12px;
    background: #175cff;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    width: 300;
    height: 100;
  }
  